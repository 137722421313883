<template>
    <SubscribeBlock v-bind="blockData" :class="CSSClasses" />
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const CSSClasses = computed(() => [props.data.width ? `width-${props.data.width}` : null]);

const blockData = {
    ...props.data.newsletter,
    newsletterFormName: props.data.newsletterFormName,
};
</script>
<style lang="scss" scoped>
.width-large {
    @include media-query(tablet-mw) {
        width: 130%;
        transform: translateX(-50%);
        left: 50%;
        position: relative;
    }
}
</style>
